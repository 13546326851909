import { Box, Grid, Paper, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SCOPES } from 'components/authorization/authorize';
import EmptyPanel from 'components/emptyPanel/emptyPanel';
import Loading from 'components/loading/loading';
import TabPanel from 'components/tabPanel/tabPanel';
import { FlamingoContext } from 'contexts/flamingo';
import { colorWithAlpha } from 'helpers/color-util';
import { isGlobalTemplate } from 'helpers/network-util';
import { tabA11yProps } from 'helpers/page-util';
import { LIVE_WORKSPACE } from 'helpers/workspace-util';
import LoggedinLayout from 'pages/layouts/loggedinLayout';
import History from 'pages/messaging/containers/pushNotification/history';
import Send from 'pages/messaging/containers/pushNotification/send';
import FlamingoPage from 'pages/shared/flamingoPage/flamingoPage';
import PropTypes from 'prop-types';
import React, { Suspense, useContext, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';
import { GET_NETWORK_MOBILE_APPS } from 'graphql/queries';
import useHandleError from 'hooks/useHandleError';

const buildStyles = ({ theme }) => ({
  layout: {
    overflow: 'hidden',
  },
  content: {
    height: 'calc(100vh - 186px)',
    backgroundColor: theme.palette.white,
    boxShadow: `0px 2px 4px 0px ${colorWithAlpha(
      theme.palette.grey[600],
      0.4,
    )}`,
    borderRadius: '20px',
  },
  takeoverTabs: {
    boxShadow: `0 2px 1px 0 ${colorWithAlpha(theme.palette.grey[350], 0.3)}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  tabPanel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  tabBody: {
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
});

const PushNotificationsPageContent = ({ styles }) => {
  const { networkId } = useParams();
  const [currentTab, setCurrentTab] = useState(0);
  const { activeNetwork, authorize } = useContext(FlamingoContext);
  const pushNotifications
    = activeNetwork?.configuration?.features?.pushNotifications || null;

  const handleError = useHandleError();
  const { data } = useSuspenseQuery(GET_NETWORK_MOBILE_APPS, {
    context: { headers: { network: networkId } },
    errorPolicy: 'all',
    variables: { networkId },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError),
  });

  let filteredMobileApps;
  if (process.env.REACT_APP_GOZIO_ENV !== 'prod') {
    filteredMobileApps = data.getNetwork?.mobileApps?.filter((item) => item.type !== 'customerRelease') || [];
  }

  const newData = { ...data, getNetwork: { ...data.getNetwork, mobileApps: filteredMobileApps || data.getNetwork.mobileApps } };

  if (
    isGlobalTemplate(networkId)
    || !authorize(SCOPES.PUSH_NOTIFICATIONS.VIEW)
  ) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {pushNotifications === 'on' ? (
        <FlamingoPage pageName="Push Notifications">
          <Paper>
            <Box sx={styles.content}>
              <Grid sx={styles.takeoverTabs}>
                <Tabs
                  value={currentTab}
                  onChange={(e, idx) => setCurrentTab(idx)}
                  aria-label="simple tabs"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    label="Push Notifications"
                    {...tabA11yProps('notifications', 0)}
                  />
                  <Tab label="History" {...tabA11yProps('notifications', 1)} />
                </Tabs>
              </Grid>
              <TabPanel
                value={currentTab}
                index={0}
                sx={{
                  ...styles.tabPanel,
                  ...currentTab === 0 && {
                    height: 'calc(100% - 70px)',
                    padding: '24px 0 24px 0',
                  },
                }}
              >
                <Box sx={{ ...styles.tabBody, padding: '0 24px 0 24px' }}>
                  <Suspense fallback={<Loading />}>
                    <Send networkId={networkId} data={newData} />
                  </Suspense>
                </Box>
              </TabPanel>
              <TabPanel
                value={currentTab}
                index={1}
                sx={{
                  ...styles.tabPanel,
                  ...currentTab === 1 && {
                    height: 'calc(100% - 70px)',
                  },
                }}
              >
                <Box sx={styles.tabBody}>
                  <Suspense fallback={<Loading />}>
                    <History networkId={networkId} />
                  </Suspense>
                </Box>
              </TabPanel>
            </Box>
          </Paper>
        </FlamingoPage>
      ) : (
        <EmptyPanel
          title="The Push Notifications Feature is Not Enabled"
          subtitle="Contact your Gozio representative to learn more."
        />
      )}
    </>
  );
};

PushNotificationsPageContent.propTypes = {
  styles: PropTypes.object.isRequired,
};

export const PushNotificationPage = () => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  return (
    <LoggedinLayout sx={styles.layout} supportedWorkspace={LIVE_WORKSPACE}>
      <PushNotificationsPageContent styles={styles} />
    </LoggedinLayout>
  );
};

export default PushNotificationPage;
